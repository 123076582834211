import BigNumber from"bignumber.js";export function addThousandSeparator(input){if(input){var parts=input.toString().split(".");parts[0]=parts[0].replace(/\B(?=(\d{3})+(?!\d))/g,",");return parts.join(".");}else return"";}export function addThousandSeparatorToStr(input){if(input){var parts=input.split(".");parts[0]=parts[0].replace(/\B(?=(\d{3})+(?!\d))/g,",");return parts.join(".");}else return"";}export function removeThousandSeparator(input){if(input)return parseInt(input.toString().replace(",",""));else throw"Invalid input";}export function removeThousandSeparatorFromString(input){if(input)return parseInt(input.replace(",",""));else throw"Invalid input";}export function addThousandSeparatorWithDP(input,dp){if(input&&typeof dp==="number"){var parts=input.toFixed(dp).split(".");parts[0]=parts[0].replace(/\B(?=(\d{3})+(?!\d))/g,",");return parts.join(".");}else return"";}export function formatAsThousands(input){if(input)return(input/1000).toFixed(1)+"K";else return"";}export function formatAsMillions(input){if(input)return(input/1000000).toFixed(1)+"M";else return"";}export function getChangeInFraction(prev,curr){// division by zero
if(prev===0)return 0;return(curr-prev)/prev;}export function formatAsDecimalString(input){var decimalPoint=arguments.length>1&&arguments[1]!==undefined?arguments[1]:0;// invalid params
if(!input)return"0";if(decimalPoint<0)return"0";return addThousandSeparatorToStr(input.toFixed(decimalPoint));}export function formatAsDecimalStringNoRounded(input){var decimalPoint=arguments.length>1&&arguments[1]!==undefined?arguments[1]:0;// invalid params
if(!input)return"0";if(decimalPoint<0)return"0";return addThousandSeparatorToStr(toFixedNoRounded(input,decimalPoint));}export function toFixedNoRounded(input){var fixed=arguments.length>1&&arguments[1]!==undefined?arguments[1]:2;var re=new RegExp("^-?\\d+(?:.\\d{0,"+(fixed||-1)+"})?");var data=input.toString().match(re);return data?Number(data[0]).toFixed(fixed):input.toFixed(fixed);}/**
 * Rounds a number up to two decimal places.
 * @param num The number to round up.
 * @returns The rounded number.
 */export function roundUpToTwoDP(num){return Math.ceil(num*100)/100;}/**
 * Rounds a number down to two decimal places.
 * @param num The number to round down.
 * @returns The rounded number.
 */export function roundDownToTwoDP(num){return Math.floor(num*100)/100;}export function formatBNInvestmentAmountWithDP(value){var decimalPlaces=arguments.length>1&&arguments[1]!==undefined?arguments[1]:2;if(value===undefined||value===null||value===""){return"0.00";}var bnValue=new BigNumber(value);if(bnValue.isNaN()){return"0.00";}// Format to specified decimal places
var formatted=bnValue.toFixed(decimalPlaces,BigNumber.ROUND_DOWN);// Add thousand separators
return formatted.replace(/\B(?=(\d{3})+(?!\d))/g,",");}